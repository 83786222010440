<template>
  <div id="admin-pickup-points">
    <div class="flex items-center justify-between mb-4">
      <h2>{{$route.name.includes('promo-codes') ? $t('Promo Codes') : $t('Automated Promo Codes')}}</h2>
      <span v-if="$store.state.AppActiveUser.userRole === 'growth-hacker' && $route.name.includes('promo-codes')" class="font-medium text-primary cursor-pointer">
        <vs-button
          @click="newPromoCode()"
          color="primary"
          icon-pack="feather"
          icon="icon-plus"
          size="large"
        >
        </vs-button>
      </span>
    </div>
    <shipblu-table
     :sst="true"
      multiple
      v-model="selected"
      :max-items="maximumItems"
      pagination
      search
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      :data="data"
      :tableLoader="promoLoading"
    >
      <template slot="header">
        <div class="flex flex-wrap items-center gap-3 whitespace-nowrap">
          <head-component :noActions="true" :statusFilters="statusFilters" :selected="selected" @changeFilter="changeFilter" @filterValue="filterValue = $event"></head-component>
        </div>
      </template>
      <template slot="thead">
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Code')}}</shipblu-th>
        <shipblu-th>{{$t('Scope')}}</shipblu-th>
        <shipblu-th>{{$t('Service Types')}}</shipblu-th>
        <shipblu-th>{{$t('Auto Apply')}}</shipblu-th>
        <shipblu-th>{{$t('Start Date')}}</shipblu-th>
        <shipblu-th>{{$t('Expiry Date')}}</shipblu-th>
        <shipblu-th>{{$t('Units')}}</shipblu-th>
        <shipblu-th>{{$t('Value')}}</shipblu-th>
        <shipblu-th>{{$t('No. Merchants')}}</shipblu-th>
        <shipblu-th>{{$t('Total Usage Count')}}</shipblu-th>
        <shipblu-th>{{$t('Total Usage Value')}}</shipblu-th>
        <shipblu-th v-if="$store.state.AppActiveUser.userRole === 'growth-hacker' && $route.name.includes('promo-codes')" class="order-1 flex justify-center">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].status">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex font-semibold">
              <span class="capitalize" :class="new Date(data[indextr].limits.expiry_date) >= new Date() ? 'text-green' : 'text-red'">
                {{ new Date(data[indextr].limits.expiry_date) >= new Date() ? 'Active' : 'Expired' }}
              </span>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].code" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50">
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].code }}
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].scope">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Scope')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].scope }}
            </p>
          </shipblu-td>
          
          <shipblu-td class="order-6" :data="data[indextr].serviceTypes">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Service Types')}}</p>
            <p class="sm:text-base text-sm" v-for="item in data[indextr].qualifier.service_types" :key="item.index">{{ item }}</p>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].autoApply">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Auto Apply')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].auto_apply }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].startDate">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Start Date')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].limits.start_date }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-9" :data="data[indextr].expiryDate">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Expiry Date')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].limits.expiry_date }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-10" :data="data[indextr].units">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Units')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].unit }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-11" :data="data[indextr].value">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Value')}}</p>
            <p class="sm:text-base text-sm">
              {{ Number(data[indextr].value).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </p>
          </shipblu-td>
          
          <shipblu-td class="order-12" :data="data[indextr].value">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('No. Merchants')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].total_usage_merchants_count }} / {{ data[indextr].qualifier.merchants.length }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-13" :data="data[indextr].value">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Total Usage Count')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].total_usage_count }} / {{ data[indextr].limits.total_usage_count }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-14" :data="data[indextr].value">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Total Usage Value')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].total_usage_value }} / {{ data[indextr].limits.total_cash_value }}
            </p>
          </shipblu-td>

          <shipblu-td v-if="$store.state.AppActiveUser.userRole === 'growth-hacker' && $route.name.includes('promo-codes')" class="order-2" style="vertical-align: middle;">
            <div class="md:text-center text-right">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="openPromoAndExceptionModal(data[indextr], 'Edit')"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    v-if="$route.name === 'growth-hacker-promo-codes'"
                    @click="openPromoAndExceptionModal(data[indextr], 'Duplicate')"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="CopyIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Duplicate')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-promo-code :type="type" :promoAndExceptionModal="promoCodeModal" @promoAndExceptionModal="promoCodeModal = $event" :data="promoCode" @loadData="loadPromoCodes"></add-promo-code>
  </div>
</template>

<script>
import AddPromoCode from './components/AddPromoCode.vue'
import { sendRequest }  from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'
import HeadComponent from '../merchant/components/Header.vue'
import common from '../../assets/utils/common'

export default {
  data () {
    return {
      data: [],
      statusFilters: [
        {
          name: 'All',
          value: 'all'
        },
        {
          name: 'Active',
          value: 'active'
        },
        {
          name: 'Expired',
          value: 'expired'
        }
      ],
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      filters: [],
      filterValue: '',
      searchVal: '',
      promoLoading:false,
      type: '',
      promoCodes: [],
      promoCode: {
        value: 0,
        limits: {
          expiry_date: '',
          per_merchant_cash_value: '',
          per_merchant_usage_count: '',
          start_date: '',
          total_cash_value: '',
          total_usage_count: ''
        },
        qualifier: {
          merchants: [],
          package_sizes: [],
          service_types: [],
          subscriptions: []
        },
        threshold: {
          count: 0,
          time_window: ''
        }
      },
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      promoCodeModal: false,
      searchInProgress: false,
      searchedValue: ' '
    }
  },
  watch: {
    '$route.name' () {
      this.loadPromoCodes()
    },
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadPromoCodes()
    }
  },
  methods: {
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadCodesSearch)
    },
    loadCodesSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadPromoCodes()
    },
    changeFilter () {
      this.filters = ''
      this.filters = this.filterValue
      if (this.filterValue === 'all') {
        this.filters = ''
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          page: 1,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadPromoCodes()
    },
    loadPromoCodes () {
      this.searchInProgress = true
      this.promoLoading = true
      sendRequest(true, false, this, `api/v1/discounts/promo-codes/?offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}${this.$route.name.includes('automated-codes') ? '&special_code=true' : '&special_code=false'}&expiry_status=${this.filters}`, 'get', null, true,
        (response) => {
          this.data = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.promoLoading = false
        }
      )
      this.searchInProgress = false
    },
    newPromoCode () {
      this.promoCodeModal = true
      this.type = 'Add'
      this.promoCode = {
        value: 0,
        limits: {
          expiry_date: '',
          per_merchant_cash_value: '',
          per_merchant_usage_count: '',
          start_date: '',
          total_cash_value: '',
          total_usage_count: ''
        },
        qualifier: {
          merchants: [],
          package_sizes: [],
          service_types: [],
          subscriptions: []
        },
        threshold: {
          count: 0,
          time_window: ''
        }
      }
    },
    openPromoAndExceptionModal (data, type) {
      sendRequest(false, false, this, `${this.$route.name === 'growth-hacker-exception-discount' ? 'api/v1/discounts/exception-codes/' :  'api/v1/discounts/promo-codes/'}${data.id}/`, 'get', null, true,
        (response) => {
          this.promoCode = response.data
          this.promoCode = {
            ...this.promoCode,
            unit: this.promoCode.unit === 'absolute' ? 'EGP' : 'Percentage',
            threshold: {
              count: this.promoCode.threshold ? this.promoCode.threshold.count : '',
              time_window: this.promoCode.threshold ? this.promoCode.threshold.span === 0  ? `This ${this.promoCode.threshold.time_window}`  : `Last ${this.promoCode.threshold.time_window}` : ''
            }
          }
          this.promoCodeModal = true
          this.type = type
        }
      )
    }
  },
  components: {
    AddPromoCode,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination,
    HeadComponent

  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : ''
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadPromoCodes()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
</style>

<style lang="scss">
.con-pagination-table{
  display: none;
}
</style>